(() => {
  'use strict';

  // Const
  // -----
  const MAX_VIEWPORT_WIDTH = 1920;

  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_SMALL_TABLET_WIDTH = 900;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;
  window.DESKTOP_WIDE_WIDTH = 1366;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  $WINDOW
    .on('calcSizes', () => {
      window.VIEWPORT_WIDTH = document.documentElement.clientWidth || Math.round(window.visualViewport.width) || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.height();
    })
    .on('resize', () => {
      $WINDOW.trigger('calcSizes');
    })
    .trigger('calcSizes');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDE_WIDTH = () => VIEWPORT_WIDTH > DESKTOP_WIDE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = () => VIEWPORT_WIDTH >= DESKTOP_WIDTH;
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = () => (VIEWPORT_WIDTH >= PORTRAIT_TABLET_WIDTH && VIEWPORT_WIDTH < DESKTOP_WIDTH);
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = () => VIEWPORT_WIDTH < PORTRAIT_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_SMALL_TABLET_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_SMALL_TABLET_WIDTH;
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = () => VIEWPORT_WIDTH < PORTRAIT_MOBILE_WIDTH;
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = () => VIEWPORT_WIDTH < SMALL_MOBILE_WIDTH;


  // Defaults
  // --------
  let slickBaseSettings = {
        elementAsContext: true,
        slider: '> div:first-child',
        appendArrows: '.slick-arrows',
        appendDots: '.slick-dots',
        dotsClass: '',
        autoplay: true,
        autoplaySpeed: 4000,
        speed: 300,
        centerMode: false,
        centerPadding: '0px',
        cssEase: 'ease-in-out',
        prevArrow: '<div class="prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.121 40"><path d="M20.621,1.5L2.121,20L20.621,38.5"/><line x1="4" y1="20" x2="70.621" y2="20"/></svg></div>',
        nextArrow: '<div class="next"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.121 40"><path d="M51.5,1.5L70,20L51.5,38.5"/><line x1="1.5" y1="20" x2="68" y2="20"/></svg></div>',
        pauseOnDotsHover: true,
        customPaging: () => '<div></div>',
        draggable: true,
        swipeToSlide: true,
        touchThreshold: 100
      },
      unveilSettings = {
        attribute: 'lazySrc',
        placeholder: '',
        offset: 50,
        throttle: 200
      },
      $portfolioVitrine,
      portfolioFilterLinksSelector = '.portfolio-page .portfolio-filter a',
      getPortfolioFilterValue = () => {
        let hash = decodeURIComponent(location.hash.slice(1)) || 'all',
            selector = '[data-filter="'.concat(hash, '"]');

        return {
          'isotope': (hash === 'all' ? '*' : selector),
          'selector': selector
        };
      };


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  Inputmask.extendAliases({
    'phone-mask': {
      regex: '(\\+7|8) \\([0-689]\\d{2}\\) \\d{3}(\\-\\d{2}){2}',
      placeholder: '+7 (___) ___-__-__',
      keepStatic: true
    }
  });

  // Phone
  $('input[type="tel"], input[autocomplete="tel"]').inputmask('phone-mask');



  $WINDOW
    .one('load', () => {
      $WINDOW.trigger('calcSizes');
      $DOCUMENT.trigger('headerEvents');
    })
    .on('structureScale', () => {
      $('.structure').css('transform', (VIEWPORT_WIDTH > MAX_VIEWPORT_WIDTH ? 'scale('.concat(VIEWPORT_WIDTH / MAX_VIEWPORT_WIDTH, ')') : ''));
    })
    .on('resize', () => {
      $WINDOW.trigger('structureScale');
      $DOCUMENT.trigger('headerEvents');
    })
    .trigger('structureScale');


  $DOCUMENT
    .ready(() => {
      $WINDOW.on('hashchange', () => {
        if ($portfolioVitrine && $portfolioVitrine.length)
          $portfolioVitrine.trigger('filtering.isotope');
      });


      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', e => {
          e.currentTarget.checked ? $BODY.addClass('menu-is-opened') : $BODY.removeClass('menu-is-opened');
        })
        .prop({'checked': false, 'disabled': false})
        .trigger('change');


      // Main rotator
      // ------------
      $('.first-block-rotator')
        .one('init.slick', (e, slick) => {
          if (slick.allowArrows)
            slick.$slider.addClass('slick-arrowed');
        })
        .on('beforeChange', (e, slick, currentSlide, targetSlide) => {
          let colorDarkClass = 'color-dark';

          if (slick.$appendArrows)
            slick.$appendArrows.parent()[slick.$slides.eq(targetSlide).hasClass(colorDarkClass) ? 'addClass' : 'removeClass'](colorDarkClass);

          slick.$slides.eq(targetSlide).find('> div:first-child span[data-lazy-src]').trigger('unveil.unveil');
        })
        .slick($.extend({}, slickBaseSettings, {
          autoplay: false,
          autoplaySpeed: 8000,
          fade: true
        }));


      // Portfolio vitrine filter
      // ------------------------
      ($portfolioVitrine = $('.portfolio-page .vitrine.portfolio > div'))
        .isotope({
          itemSelector: '.vitrine-item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null,
          filter: getPortfolioFilterValue().isotope
        })
        .on('setActiveLink.isotope', (e, selector) => {
          $(portfolioFilterLinksSelector).removeClass('active').filter(selector).addClass('active');
        })
        .on('filtering.isotope', () => {
          let filterValue = getPortfolioFilterValue();

          $portfolioVitrine
            .trigger('setActiveLink.isotope', [filterValue.selector])
            .isotope({filter: filterValue.isotope});
        })
        .on('arrangeComplete.isotope', (e, items) => {
          $(items.map(item => item.element)).find('.vitrine-item-image [data-lazy-src]').trigger('unveil.unveil');
        })
        .trigger('setActiveLink.isotope', [getPortfolioFilterValue().selector]);


      // Lazy load
      // ---------
      $('.first-block-item > div:first-child span[data-lazy-src]').unveil($.extend({}, unveilSettings, {
        attribute: 'lazySrcSm',
        offset: 0,
        breakpoints: [
          {
            minWidth: 600,
            attribute: 'lazySrc'
          }
        ]
      }));

      $('.first-block-mini .first-block-item > div:first-child span[data-lazy-src], .vitrine-item-image [data-lazy-src], .video-frame > a, .gallery img, .incut-image > div:first-child > div').unveil(unveilSettings);
    })

    // Header events
    // -------------
    .on('headerEvents', () => {
      if (IS_DESKTOP_WIDTH()) {
        if (!$BODY.hasClass('is-desktop-events')) {
          $BODY
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events')
            .css('padding-top', '');

          $HEADER.removeClass('fixed');

          // Header menu desktop events
          // --------------------------
          $DOCUMENT
            .off('click.closeHeaderMenu')
            .off('click.headerMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .find('li').removeClass('is-hovered')
            .find('ul').removeAttr('style');
        }
      }
      else {
        if (!$BODY.hasClass('is-mobile-events')) {
          $BODY
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $HEADER.addClass('fixed');

          // Header menu mobile events
          // -------------------------
          $DOCUMENT
            // Close header menu
            .on('click.closeHeaderMenu', e => {
              if (!($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length || $(e.target).closest('.header-menu').length)) {
                e.stopPropagation();

                $MENU_CHECKBOX.prop('checked', false).trigger('change');
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu > ul a, .header-menu > ul div > span, .header-menu > ul .dropmarker', e => {
              e.preventDefault();

              let $self = $(e.currentTarget),
                  $parent = $self.closest('li'),
                  isWithDropdown = $parent.hasClass('with-dropdown'),
                  isOnHover = $parent.hasClass('is-hovered');


              if ($self.prop('tagName').toUpperCase() === 'A')
                location.href = $self.prop('href');
              else if (isWithDropdown) {
                if (isOnHover)
                  $parent.removeClass('is-hovered').children('ul').slideUp(300);
                else {
                  //$parent.siblings().removeClass('is-hovered').children('ul').slideUp(300);
                  $parent.addClass('is-hovered').children('ul').slideDown(300);
                }
              }
            });
        }

        $BODY.css('padding-top', HEADER_HEIGHT);
      }
    })

    // Portfolio filter
    // ----------------
    .on('click.portfolioFilterLink', portfolioFilterLinksSelector, e => {
      e.preventDefault();

      location.hash = encodeURIComponent($(e.currentTarget).data('filter'));
    })

    // Callback form open
    // ------------------
    .on('click.callbackFormButton', '.btn-callback', e => {
      e.preventDefault();

      $.fancybox.open({
        type: 'inline',
        src: '#callback-popup-form'
      });
    })

    // Video frame load
    // ----------------
    .on('click.videoFrame', '.video-frame > a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget),
          $iframe = $self.next();

      $iframe.attr('src', () => $iframe.data('src'));

      if (($self.data('popup') !== undefined) || (IS_WIDTH_LESS_PORTRAIT_TABLET() && ($self.outerWidth() <= VIEWPORT_WIDTH / 2)))
        $.fancybox.open($iframe);
      else
        $iframe.parent().addClass('active');
    })

    // Faq list
    // --------
    .on('click.faq-list', '.faq-list > div', e => {
      e.preventDefault();

      $(e.currentTarget).toggleClass('active').children('div:last-child').slideToggle(250);
    })

    // Scroll to
    // ---------
    .on('scrollTo', (e, $element, speed, offset) => {
      if ($element === undefined)
        $element = $(e.target);

      if (speed === undefined)
        speed = $element.data('scrollSpeed') || 200;

      if (offset === undefined)
        offset = $element.data('scrollOffset') || 0;

      $WINDOW.scrollTo($element, {axis: 'y', duration: speed, offset: parseInt(offset)/* - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT)*/});
    })
    .on('click.jsScrollTo', '.js-scroll-to', e => {
      e.preventDefault();

      let $link = $(e.currentTarget),
          $element = $($link.attr('href').substring($link.attr('href').lastIndexOf('#'))),
          speed = $link.data('scrollSpeed') || 200,
          offset = $link.data('scrollOffset') || undefined;

      $DOCUMENT.trigger('scrollTo', [$element, speed, offset]);
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', e => {
      e.preventDefault();

      let $self = $(e.currentTarget);

      if (!$self.hasClass('active')) {
        let $container = $self.closest('.js-tabs'),
            $links = $container.find('a'),
            $destination = !!$container.data('tabsDest') ? $($container.data('tabsDest')) : $container.next(),
            cssClass = $container.data('tabCssClass') || 'active';

        $self.addClass('active');
        $links.not($self).removeClass('active');
        $destination.children().removeClass(cssClass).eq($links.index($self)).addClass(cssClass);
      }
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: (widget, current) => {
      switch (current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: (widget, current) => {
      if (current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH())
        current.$content.find('.js-autofocus-inp').trigger('focus');
    }
  });
})();